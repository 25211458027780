export var screenOptionsList = [{
  title: "商品分类",
  idFlag: 2,
  options: [],
  isShowMore: false
}, {
  title: "剂型",
  idFlag: 3,
  options: [],
  isShowMore: false
}];